<template>
  <v-app-bar
    elevation="24"
    color="transparent"
    class="appbar"
  >
    <img src="@/assets/images/logo.png" alt="TESS" class="logosh" height="64px"> 
    <!-- Language switch AZ/RU -->
    <img src="@/assets/images/araz.png" alt="TESS" class="mx-2"  height="20px"> 

    <div class="hidden-sm-and-down">
      <v-btn 
        v-for="(item, index) in items"
        :key="index"
        :to="item.to"
        class="buttonh ml-3" text>
        {{ item.title }}
      </v-btn>
    </div>
    
    <v-spacer></v-spacer>
    <!-- Right part -->
    <div class="hidden-sm-and-down" v-if="isLogin">
      <v-btn
        :to="'/profil'"
        class="buttonh" text>
        {{ $t('header_personal_cabinet') }}
      </v-btn>

      <v-btn :to="'/fiscalEnter'" class="buttonh" text>
        {{ $t('header2_register_check') }}
      </v-btn>
            
      <!-- Signout button -->
      <v-btn
        class="buttonh"
        @click="logOut"
        text
      >
        ÇIXIŞ      
      </v-btn>
    </div>

    <!-- Register / Login buttons -->
    <div v-else>
      <v-btn v-if="pageIsLogin" class="buttonh" :to="'/register'" text>
        {{ $t('header_register') }}
      </v-btn>

      <v-btn v-else class="buttonh" :to="'/login'" text>
        {{ $t('header_login') }}
      </v-btn>
    </div>

    <!-- Hamburger menu -->
    <div class="hidden-md-and-up">
      <v-menu offset-y>
        <template v-slot:activator="{on, attrs}">
          <v-app-bar-nav-icon  
          v-bind="attrs"
          v-on="on"
          class="white--text"
          ></v-app-bar-nav-icon>
        </template>

        <v-list>
          <v-list-item v-for="(item, index) in items" :key="index" :to="item.to" v-scroll-to="item.to">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          
          <div v-if="isLogin">
            <v-list-item :to="'/profil'">
              <v-list-item-title>{{ $t('header_personal_cabinet') }}</v-list-item-title>
            </v-list-item>
  
            <v-list-item :to="'/fiscalEnter'">
              <v-list-item-title>{{ $t('header2_register_check') }}</v-list-item-title>
            </v-list-item>
            
            <v-list-item @click="logOut">
              <v-list-item-title>{{ $t('header2_signout') }}</v-list-item-title>
            </v-list-item>
          </div>

          <!-- Register / Login buttons -->
          <div v-else>
            <v-list-item v-if="pageIsLogin" :to="'/register'">
              <v-list-item-title>{{ $t('header_register') }}</v-list-item-title>
            </v-list-item>

            <v-list-item v-else :to="'/login'">
              <v-list-item-title>{{ $t('header_login') }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import user from '../../store/user'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header2',
  data: () => ({
    isLogin: false
  }),
  props: ['pageIsLogin'],
  computed: {
    items() {
      return [
        { title: this.$t('header2_main_page'), to: "/" },
      ]
    }
  },
  methods: {
    logOut(){
      this.loading = true

      user.dispatch('updateLogin',{user:'', expires:-1})

      this.loading = false
      location.href='/'
      //  this.$router.push('/login')
    },
    changeLocation(){
      if(this.$i18n.locale == 'az'){
        localStorage.setItem('lang','ru')
        this.$i18n.locale = 'ru'
      }
      else{
        localStorage.setItem('lang','az')
        this.$i18n.locale='az'
      }
      // location.reload()
    }
  },
  created(){
    this.isLogin = user.getters.is_login
  }
}
</script>

<style>
@import url('../../assets/css/custom.css');
</style>